<template>
  <div class="d-flex flex-colum align-center justify-center h-full">
    <v-container fill-height>
      <v-snackbar
          v-model="errorSignin"
          :multi-line="multiLine"
        >
              {{ errorMessageSignin }}
              <br>
              {{errors[0]}}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="setErrorSignin"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      <v-row>
        <v-col align="center">
          <v-card
            elevation="0"
            max-width="500"
            rounded="sm"
            class="mx-auto rounded-xl"
          >
           <!-- <div class="pt-2" style="color:red" v-if="errorSignin">
              {{ errorMessageSignin }}
              <br>
              {{errors[0]}}
           </div> -->
            <v-card-text class="pa-5">
              <v-text-field
                label="Email"
                :rules="[rules.required]"
                v-model.trim="userData.email"
                :suffix="emailDomain"
                required
                outlined
              >
              </v-text-field>

              <v-text-field
                hint="At least 8 characters"
                :rules="[rules.required, rules.password]"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                label="Password"
                required
                
                v-model.trim="userData.password"
                outlined
              >
              </v-text-field>

              <v-btn
                :loading="$store.state.auth.loading"
                @click="Login"
                :disabled="!userData.email || !userData.password"
                depressed
                block
                class="primary"
                height="50"
                rounded
              >
                Sign In
              </v-btn>
            </v-card-text>
          </v-card>

          <SocialLogin :page="'signIn'" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import SocialLogin from "@/components/Utils/SocialLogin";
import { mapGetters } from "vuex";

export default {
  components: {
    SocialLogin,
  },
  mixins: [validationMixin],
  validations: {
    password: { required, maxLength: maxLength(8) },
    email: { required, email },
  },
  data() {
    return {
      snackbar:false,
      multiLine:true,
      passwordErrorMessage:"A password must be eight characters including one uppercase letter,one lowercase letter, one special character and alphanumeric characters.",
      emailDomain: "", //previously @itcan.ae
      passwordRules: [
				{ message:'One lowercase letter required.', regex:/[a-z]+/ },
				{ message:"One uppercase letter required.",  regex:/[A-Z]+/ },
				{ message:"8 characters minimum.", regex:/.{8,}/ },
				{ message:"One number required.", regex:/[0-9]+/ }
			],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) =>
          (value && value.length >= 8) || "Min 8 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        password: (value) => {
          const pattern =
            /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
          return pattern.test(value) || this.passwordErrorMessage ;
        },
      },
      showPassword: false,
      userData: {
        email: "",
        password: null,
        social: 0
      },
      errors:[],
    };
  },
  mounted() {
    this.$v.$reset();
  },
  created(){
    this.$store.dispatch('clearErrors');
  },
  computed: {
    ...mapGetters(["errorSignin","errorMessageSignin"]),
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.maxLength &&
        errors.push("Password must be at most 8 characters long");
      !this.$v.password.required && errors.push("Password is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      pattern.test(this.userData.email) || errors.push("Invalid e-mail.");
      // if (!this.$v.email.$dirty) return;
      // !this.$v.email.email && errors.push("Must be valid e-mail");
      // !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
  },
  methods: {
    setErrorSignin(){
      this.$store.dispatch("clearErrors");
    },
    Login() {
        const passError = this.passwordValidation();
        if (!passError.valid){
          console.log('A password must be eight characters including one uppercase letter,one lowercase letter, one special character and alphanumeric characters.');
        }
        else{
          const EMAIL = {
            ...this.userData,
            email: this.userData.email,
          };
          this.$store.dispatch("login", EMAIL);
        }
    },
    passwordValidation () {
			this.errors = []
			for (let condition of this.passwordRules) {
				if (!condition.regex.test(this.userData.password)) {
					this.errors.push(condition.message)
				}
			}
			if (this.errors.length === 0) {
				return { valid:true }
			} else {
				return { valid:false }
			}
		},
  },
};
</script>

<style>
</style>