<template>
  <v-card outlined max-width="500" color="transparent" rounded="sm" class="mx-auto rounded-xl">
    <v-card-text class="pa-2 pt-5 d-flex flex-column">
      <h3 class="font-weight-bold gray--text text-uppercase text-center">
        {{ page == "signIn" ? "or sign In with" : "or sign up with.." }}
      </h3>
      <div class="d-flex flex-row align-center justify-center my-2">
        <!-- <v-btn @click="handleClickSignIn" btn fab icon>
          <div class="white mx-2 rounded-xl pointer">
            <v-img
              src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-webinar-optimizing-for-success-google-business-webinar-13.png"
              lazy-src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-webinar-optimizing-for-success-google-business-webinar-13.png"
              alt="google_logo"
              contain
              height="40px"
              width="40px"
            ></v-img>
          </div>
        </v-btn> -->
        <div id="google-signin-button"></div>
        <!-- <v-btn disabled btn fab icon>
          <div class="white mx-2 rounded-xl pointer">
            <v-img
              src="https://pnggrid.com/wp-content/uploads/2021/11/Meta-1024x576.png"
              lazy-src="https://pnggrid.com/wp-content/uploads/2021/11/Meta-1024x576.png"
              alt="facebook_logo"
              contain
              width="40px"
              height="40px"
            ></v-img>
          </div>
        </v-btn> -->
      </div>

      <span v-if="page == 'signUp'" class="text-center black--text">Signing up with Google is easy! No extra passwords to
        remember.</span>
    </v-card-text>
  </v-card>
</template>

<script>
import JwtService from "@/core/services/jwt.service";
import VueJwtDecode from 'vue-jwt-decode'
import store from "@/store";
export default {
  props: {
    page: {
      type: String,
    },
  },
  data() {
    return {
      isInit: false,
      isSignIn: false,
    };
  },
  async created() {
    let that = this;
      that.isSignIn = store.state.auth.isAuthenticated;
      if(this.isSignIn){
        this.$router.push('/')
      }
    // let checkGauthLoad = setInterval(function () {
    //   that.isInit = that.$gAuth.isInit;
    //   that.isSignIn = that.$gAuth.isAuthorized;
    //   if (that.isInit) clearInterval(checkGauthLoad);
    // }, 1000);
    window.onload = this.onload
  },
    mounted: function () {
        window.loginscreen=this;
    },
  methods: {
    onload() {
      google.accounts.id.initialize({
        client_id: '135626791749-5jrgb5k46ho9lmpug1n5bjkv6fgmjdr6.apps.googleusercontent.com',
        callback: function (response) {

          const credential = response.credential;
          // Perform necessary actions like sending the credential to your server, storing user data, etc.
          console.log(response, 'response');
          // const idToken = credential.id_token;
          const GoogleUser = VueJwtDecode.decode(credential)
          loginscreen.handleClickSignIn(GoogleUser)
        }
      });
      // google.accounts.id.prompt();// also display the One Tap dialog
      google.accounts.id.renderButton(document.getElementById('google-signin-button'),
        {
          client_id: '135626791749-5jrgb5k46ho9lmpug1n5bjkv6fgmjdr6.apps.googleusercontent.com',
          theme: 'filled',
          text: 'Sign in with Google',
          size: 'large',
          auto_select: true,
          ux_mode: 'redirect',
          callback: function (response) {
            console.log(response, 'response1');
          }
        });
    },
    handleClickSignIn(GoogleUser) {
      //       const GoogleUser=VueJwtDecode.decode(response.credential);
      // console.log(response,'response');
      console.log(GoogleUser, 'GoogleUser');
      // this.$gAuth
      //   .signIn()
      //   .then((GoogleUser) => {
      // console.log("GoogleUser", GoogleUser);
      // console.log("getId", GoogleUser.getId());
      // console.log("getBasicProfile", GoogleUser.getBasicProfile().Bv);
      // console.log("getAuthResponse", GoogleUser.getAuthResponse());
      // console.log(
      //   "getAuthResponse",
      //   this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
      // );

      const loginData = {
        email: GoogleUser.email,
        name: GoogleUser.name,
        social: 1,
      };

      if (this.page == "signIn") {
        this.$store.dispatch("login", loginData);
      } else if (this.page == "signUp") {
        // loginData.name = GoogleUser.getBasicProfile().tf;
        this.$store.dispatch("register", loginData);
      } else throw new Error("Unhandled Exception.");

      // this.isSignIn = true;
      /// this.$gAuth.isAuthorized;
      // })
      // .catch((error) => {
      //   console.log(error);
      //   console.log("Google SSO Error", error);
      //   this.$toast.open({
      //     message: `${error.reason == 'Account domain does not match hosted_domain specified by gapi.auth2.init.' ? 'Only itcan.ae domain is allowed.' : 'Error'}`,
      //     type: "error",
      //     duration: 3000,
      //     position: "top-right",
      //     dismissible: true,
      //   });
      //   throw error;
      // });
    },
  },
};
</script>